<template>
  <div class="adset">
    <div class="adset-list">
      <list-bar :params="params" @setParams="setParams" :dateRange="dateRange"></list-bar>
      <p class="campaign-name">{{ campaignMsg.ad.campaignName }}</p>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="list.length">
        <adset-card
          v-for="(item, index) in list"
          :key="item.adGroupId"
          :data="{
            ad: item.ad,
            statistics: { ...item.statistics, productReport: campaignMsg.statistics.productReport },
            campaignCost: campaignMsg.statistics.spend,
          }"
          :listParams="listParams"
          :params="params"
          :initData="initData"
          @emitOpenSchedule="emitOpenSchedule"
          :index="index"
          @changeStatus="changeStatus"
          @setData="setData"
          :dateRange="dateRange"
        ></adset-card>
      </van-pull-refresh>
      <van-empty description="暂无数据" v-else />
    </div>
  </div>
</template>
<script>
import listBar from '@/views/components/listBar.vue';
import adsetCard from '@/views/adset/adsetCard.vue';
import globalData from '@/minixs/data.js';
import editAdMix from '@/views/components/editAdMix.js';

export default {
  components: {
    listBar,
    adsetCard,
  },
  mixins: [globalData, editAdMix],
  data() {
    return {
      params: {
        currency: null,
        time: 1,
        syncData: false,
        sort: 'origin_spend#DESC',
      },
      campaignMsg: {},
    };
  },
  computed: {},
  methods: {
    getList() {
      if (!this.params.time && !this.dateRange) {
        return;
      }
      let { platform, credentialId, adAccountId, adAccountName, adAccountStatus, campaignId, campaignName } =
        this.campaignMsg.ad;
      let params = {
        platform,
        startDate: this.params.time
          ? this.formatDate(this.dateList.filter((v) => v.value == this.params.time)[0].start(), { tag: 1 })
          : this.formatDate(this.dateRange[0], { tag: 1 }),
        endDate: this.params.time
          ? this.formatDate(this.dateList.filter((v) => v.value == this.params.time)[0].end(), { tag: 1 })
          : this.formatDate(this.dateRange[1], { tag: 1 }),
        currency: this.params.currency,
        sort: 'SPEND',
        level: 'adset',
        orderBy: this.params.sort.split('#')[0],
        ascOrDesc: this.params.sort.split('#')[1],
        ad: { platform, credentialId, adAccountId, adAccountName, adAccountStatus, campaignId, campaignName },
      };
      this.getDefaultList(params);
    },
    changeStatus(params, idKey, id, key, val) {
      this.editStatus(params, idKey, id, key, val);
    },
  },
  mounted() {
    // this.getList();
  },
  created() {
    let { campaignMsg, currency, time } = this.$route.query;
    let { dateRange } = this.$route.query;
    dateRange = JSON.parse(dateRange);

    this.campaignMsg = JSON.parse(campaignMsg);
    this.params.currency = currency;
    this.params.time = time ? time / 1 : null;
    this.dateRange = dateRange && dateRange.length ? dateRange : null;
    if (this.dateRange) {
      this.dateRange = [new Date(this.dateRange[0]), new Date(this.dateRange[1])];
    }
    if (!this.params.time && this.dateRange) {
      this.getList();
    }
    //
  },
};
</script>
<style lang="less">
.adset {
  .van-dropdown-menu__title--active,
  .van-dropdown-item__option--active .van-dropdown-item__icon,
  .van-dropdown-item__option--active {
    color: #1989fa;
  }
  .top-bar {
    display: flex;
    width: 100%;
    .platform {
      flex: 240px 0 0;
      .van-dropdown-menu__bar {
        box-shadow: none;
      }
    }
    .search {
      flex: 1;
    }
  }
  .adset-list {
    padding: 0 40px;
    border-top: 1px solid #ebedf0;
    .campaign-name {
      font-size: 32px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ebedf0;
    }
  }
}
</style>

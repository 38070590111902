<template>
  <div class="adset-card">
    <div class="card-title">
      <p @click="editName">
        <span v-show="!showInput">{{ data.ad.adGroupName }}</span>
        <van-field class="editNameInput" v-show="showInput" v-model="newName" placeholder="请输入用户名">
          <template #button>
            <van-button type="primary" class="mr5" size="mini" @click.stop="changeName" :loading="loading"
              >确认</van-button
            >
            <van-button type="default" size="mini" @click.stop="setShowInput(false)">取消</van-button>
          </template>
        </van-field>
      </p>
      <!-- <p class="account">{{data.accountName}}</p> -->
    </div>
    <div class="msg-wrap" v-if="data.statistics">
      <div class="adsetData" @click="goAds">
        <div class="data-item">
          <p class="desc">花费</p>
          <span>{{ data.statistics.currency }}{{ data.statistics.spend.toFixed(2) }}</span>
        </div>
        <div class="data-item">
          <p class="desc">订单/成本</p>
          <span>{{ data.statistics.orderCount }}/{{ data.statistics.perOrderCost.toFixed(2) }}</span>
        </div>
        <div class="data-item">
          <p class="desc">添加支付/成本</p>
          <span>{{ data.statistics.addPayCount }}/{{ data.statistics.perAdPayCost.toFixed(2) }}</span>
        </div>
        <div class="data-item" v-if="data.ad.platform == 'line'">
          <p class="desc">dopay/成本</p>
          <span
            >{{ data.statistics.dopay }}/{{
              data.statistics.finalCost ? data.statistics.finalCost.toFixed(2) : '--'
            }}</span
          >
        </div>
        <div class="data-item">
          <p class="desc">毛利率</p>
          <span>{{
            productReport() && productReport().profitRatePre ? productReport().profitRatePre.toFixed(2) + '%' : '--'
          }}</span>
        </div>
        <div class="data-item">
          <p class="desc">{{ data.ad.platform == 'twitter' ? '链接' : '' }}点击数</p>
          <span>{{ data.statistics.click || 0 }}</span>
        </div>
        <div class="data-item">
          <p class="desc">{{ data.ad.platform == 'twitter' ? '链接' : '' }}点击成本</p>
          <span>{{ data.statistics.perClickCost || '--' }}</span>
        </div>
        <div class="data-item" v-if="data.ad.platform == 'twitter'">
          <p class="desc">点赞数</p>
          <span>{{ data.statistics.like || 0 }}</span>
        </div>
        <div class="data-item">
          <p class="desc">经营评估</p>
          <van-image
            width="20"
            height="20"
            :src="iconSrc[productReport().profitRateResult]"
            v-if="productReport() && Object.keys(productReport()).length"
          />
          <span v-else>--</span>
        </div>
      </div>
      <div class="arrow"><van-icon name="arrow" /></div>
    </div>
    <div class="tool-budget">
      <div class="tool-item">
        <p v-if="data.ad.adGroupDailyBudget || data.ad.adGroupLifetimeBudget">
          <span>{{ data.ad.adGroupDailyBudget ? '日' : '总' }}预算({{ data.statistics.currency }})：</span>
          <span class="money"
            >{{ data.ad.adGroupDailyBudget || data.ad.adGroupLifetimeBudget }}
            <van-icon name="edit" @click.stop="editBudget" />
          </span>
        </p>
        <p v-else>预算：--</p>
      </div>
      <div class="tool-item" v-if="data.ad.adGroupBidAmount">
        <p>
          <span>出价：</span>
          <span class="money"
            >{{ data.ad.adGroupBidAmount }}
            <van-icon name="edit" @click.stop="editBidAmount" />
          </span>
        </p>
      </div>
    </div>
    <div class="tools">
      <div class="tool-item status">
        <span>状态：</span>
        <van-switch
          :value="data.ad.adGroupStatus"
          @input="onInput"
          active-value="ACTIVE"
          inactive-value="PAUSED"
          size="16px"
          :disabled="data.ad.adGroupStatus == 'DELETED' || data.ad.adGroupStatus == 'DELETED'"
          id="changeStatus"
        />
      </div>
      <div class="tool-buttons">
        <div
          class="tool-item tool-button"
          v-if="data.ad.adGroupStatus == 'PAUSED' && data.ad.open_schedule.length == 0"
        >
          <van-button text="定时开启" type="info" round size="mini" @click="openClockShow = true" />
        </div>
        <div
          class="tool-item tool-button"
          v-if="
            data.ad.adGroupStatus == 'ACTIVE' &&
            (data.ad.adGroupDailyBudget || data.ad.adGroupLifetimeBudget) &&
            data.ad.open_schedule.length == 0
          "
        >
          <van-button text="定时改预算" type="info" round size="mini" @click="f_showDialogBudgetClock" />
        </div>
        <div class="tool-item tool-button" @click.stop="handleOpenOperateDraw">
          <van-button type="info" size="mini">操作日志</van-button>
        </div>
        <div class="tool-item tool-button" @click.stop="copyAdset">
          <van-button type="info" size="mini">复制</van-button>
        </div>
        <div class="tool-item tool-button" @click.stop="deleteAdset('DELETED')">
          <van-button type="info" size="mini">删除</van-button>
        </div>
      </div>
    </div>
    <div class="tools">
      <div class="tool-item">
        <div v-if="data.ad.open_schedule && data.ad.open_schedule.length > 0" :key="tableKey">
          <p v-for="(item, k) in data.ad.open_schedule" :key="data.id + k" class="open-schedule">
            <span v-if="item && item.type == 1" class="set-open"
              >定时开启任务:{{ item.timeStr }}
              <i class="el-button" @click="cancelSet('task', data, index, k)">取消</i></span
            >
            <span v-if="item && item.type == 2" class="budget-span"
              >定时修改预算:{{ item.timeStr }}
              <i class="el-button" @click="cancelSet('budget', data, index, k)">取消</i></span
            >
            <span v-if="item && item.type == 2" class="budget-span">预算金额：{{ item.budget }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 改预算 -->
    <edit-budget
      :editBudgetShow.sync="editBudgetShow"
      :data="data"
      level="adset"
      :type="type"
      @setListItem="setListItem"
    ></edit-budget>
    <!-- 定时开启 -->
    <openClock
      :openClockShow.sync="openClockShow"
      :data="data"
      :initData="initData"
      :type="type"
      v-on="$listeners"
      @settingComplete="settingComplete"
      level="adset"
    ></openClock>
    <!-- 定时开启预算 -->
    <open-clock-budget
      :openClockBudgetShow.sync="openClockBudgetShow"
      :data="data"
      :initData="initData"
      ref="campaignBudget"
      level="adset"
      @settingComplete="settingComplete"
      :type="type"
    >
    </open-clock-budget>
    <!-- 复制 -->
    <van-popup v-model="showCopy" position="bottom" :closeable="true">
      <div class="copy-wrap">
        <van-field v-model="copyCount" label="复制份数：" placeholder="请输入复制份数" type="digit" />
        <van-field v-model="adsetDailyBudget" label="日预算：" placeholder="请输入广告组日预算" type="digit" />
        <div class="btn-wrap">
          <van-button type="default" size="small" round @click="showCopy = false"> 取消 </van-button>
          <van-button type="info" size="small" round @click="copy"> 确定 </van-button>
        </div>
      </div>
    </van-popup>
    <operateLogPop
      ref="operateLogPop"
      :logFinish="logFinish"
      :logLoading="logLoading"
      v-model="logLoading"
      :operateContent="operateContent"
      @load="load"
    ></operateLogPop>
  </div>
</template>
<script>
import editBudget from '@/views/components/editBudget.vue';
import { ProductReport } from './ProductReport.js';
import openClock from '@/views/components/openClock.vue';
import globalData from '@/minixs/data';
import editOpenClock from '../components/editOpenClock';
import openClockBudget from '@/views/components/openClockBudget.vue';
import { Dialog, Notify } from 'vant';
import { copyAdset, copyAdAsync, mutateName } from '@/api/campaign';
import { operateMethod } from '../adset/operateLog.js';
import operateLogPop from '@/views/components/operateLogPop.vue';
export default {
  props: {
    initData: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    dateRange: {
      type: Array,
      default: () => [],
    },
    level: {
      type: String,
      default: 'adset',
    },
  },
  mixins: [globalData, editOpenClock, operateMethod],
  components: {
    editBudget,
    openClockBudget,
    openClock,
    operateLogPop,
  },
  data() {
    return {
      openClockBudgetShow: false,
      editBudgetShow: false,
      type: 'budget',
      showCopy: false,
      copyCount: 1,
      adsetDailyBudget: 0,
      showInput: false,
      newName: '',
      loading: false,
      // level: 'adset',
    };
  },
  methods: {
    onInput(v) {
      if (this.data.ad.adCampaignStatus == 'CLOSED') {
        Notify({ type: 'danger', message: '关闭状态不支持修改哦~~' });
        return false;
      }
      let params = {
        platform: this.data.ad.platform,
        level: 'adset',
        accountId: this.data.ad.adAccountId,
        adsetId: this.data.ad.adGroupId,
        credentialId: this.data.ad.credentialId,
        campaignId: this.data.ad.campaignId,
        status: v,
      };
      // console.log(params);
      this.$emit('changeStatus', params, this.data.ad.adGroupId, 'adGroupId', 'adGroupStatus', v);
    },
    goAds() {
      this.$router.push({
        name: 'ads',
        query: {
          adsetMsg: JSON.stringify(this.data),
          currency: this.params?.currency,
          time: this.params?.time,
          dateRange: JSON.stringify(this.dateRange),
        },
        params: {},
      });
    },
    editBudget() {
      if (this.data.ad.adGroupStatus == 'DELETED' || this.data.ad.adGroupStatus == 'DELETED') {
        Notify({ type: 'danger', message: '关闭状态不支持修改哦~~' });
        return false;
      }
      this.type = 'budget';
      this.editBudgetShow = true;
    },
    editBidAmount() {
      if (this.data.ad.adGroupStatus == 'DELETED' || this.data.ad.adGroupStatus == 'DELETED') {
        Notify({ type: 'danger', message: '关闭状态不支持修改哦~~' });
        return false;
      }
      this.type = 'bidAmount';
      this.editBudgetShow = true;
    },
    productReport() {
      if (this.data.statistics.productReport && Object.keys(this.data.statistics.productReport).length) {
        let purchase = this.data.statistics.orderCount;
        let moneyRate = this.data.statistics.productReport.adCost / this.data.campaignCost;
        let spend = this.data.statistics.spend * moneyRate;
        // let purchase = this.value.row.local_order?`${this.value.row.local_order.validOrderCount}`:0
        return new ProductReport(
          this.data.statistics.productReport,
          // this.value.row.campaignPurchase,
          purchase,
          // this.value.row.campaignCost,
          spend,
        );
      } else {
        return {};
      }
    },
    // 跳转经营分析
    goReport(val) {
      if (val) {
        this.$router.push({
          name: 'productReport',
          query: { val: JSON.stringify(val) },
        });
      }
    },
    copySure() {
      let params = {
        adsetIds: [this.data.ad.adGroupId],
        thirdUserId: '',
        uId: JSON.parse(localStorage.getItem('userInfo')).id,
        copyCount: this.copyCount,
        accountId: this.data.ad.adAccountId,
        credentialId: this.data.ad.credentialId,
      };
      this.$showLoading();
      copyAdset(params, this.data.ad.platform)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            Notify({ type: 'success', message: '复制成功，请稍后查看结果' });
            this.showCopy = false;
            this.copyCount = 1;
          }
        })
        .catch(() => {
          this.$hideLoading();
        });
    },
    copyAdAsync() {
      const {
        data: { ad },
        level,
        adsetDailyBudget,
        copyCount,
      } = this;
      const params = {
        adsetDailyBudget,
        level,
        fromAccountId: ad.adAccountId,
        toAccountId: ad.adAccountId,
        platform: ad.platform,
        fromCampaign: ad.campaignId,
        toCampaign: ad.campaignId,
        fromAdGroup: ad.adGroupId,
        adsetActive: ad.adGroupStatus === 'ACTIVE',
        copyNum: copyCount,
      };
      this.$showLoading();
      copyAdAsync(params)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            Notify({ type: 'success', message: '复制成功，请稍后查看结果' });
            this.showCopy = false;
            this.copyCount = 1;
          }
        })
        .catch(() => {
          this.$hideLoading();
        });
    },
    // 复制
    copy() {
      const {
        adsetDailyBudget,
        data: { ad },
      } = this;
      if (ad.platform == 'snapchat' && !!String(adsetDailyBudget) && adsetDailyBudget < 5) {
        Notify({ type: 'warning', message: '组日预算最少5' });
        return;
      }
      Dialog.confirm({
        title: '确认',
        message: `确定要复制${this.copyCount}份该广告组到当前广告系列吗？`,
      })
        .then(() => {
          // this.copySure();
          this.copyAdAsync();
        })
        .catch(() => {
          // on cancel
        });
    },
    copyAdset() {
      this.adsetDailyBudget = this.data.ad.adGroupDailyBudget || null;
      this.showCopy = true;
    },
    deleteAdset(v) {
      Dialog.confirm({
        title: '确认',
        message: '确定要删除该广告组',
      })
        .then(() => {
          this.onInput(v);
        })
        .catch(() => {
          // on cancel
        });
      // this.onInput(v)
    },
    // 修改预算
    setListItem(key, val) {
      this.$emit('setData', this.data.ad.adGroupId, 'adGroupId', key, val);
    },
    // 编辑名字
    editName() {
      this.newName = this.data.ad.adGroupName;
      this.showInput = true;
    },
    changeName() {
      this.loading = true;
      const {
        ad: { platform, campaignId, adAccountId, adGroupId },
      } = this.data;
      const newName = this.newName;
      let params = {
        platform: platform,
        campaignId: campaignId,
        accountId: adAccountId,
        name: newName,
        level: 'adset',
        adsetId: adGroupId,
      };
      mutateName(params)
        .then(() => {
          this.showInput = false;
          // this.$emit("getList");
          this.$set(this.data.ad, 'adGroupName', newName);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setShowInput(bool) {
      console.log(bool);
      this.showInput = bool;
      console.log(this.showInput);
    },
  },
};
</script>
<style lang="scss" scoped>
.copy-wrap {
  margin-top: 100px;
  padding: 20px;
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 50px;
    padding-bottom: 0;
    .van-button {
      width: 150px;
    }
  }
}
.msg-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .arrow {
    flex: 30px 0 0;
  }
}
.adset-card {
  border-bottom: 1px solid #ebedf0;
  padding: 10px 0;
  .card-title {
    // display: flex;
    width: 100%;
    font-size: 30px;
    line-height: 1.5;
    .account {
      font-size: 24px;
      color: #999;
    }
  }
  .adsetData {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    font-size: 26px;
    flex-wrap: wrap;
    flex: 1;
    overflow: hidden;
    .data-item {
      width: 25%;
    }
    .desc {
      margin-bottom: 20px;
    }
    span {
      color: #ff0000;
      line-height: 52px;
    }
  }
  .tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    .status {
      width: 200px;
      height: 42px;
      span {
        vertical-align: super;
      }
    }
  }
  .tool-budget {
    display: flex;
    font-size: 24px;
    .tool-item {
      font-size: 24px;
      margin-right: 30px;
      margin-bottom: 10px;
    }
  }
  .tool-buttons {
    display: flex;
    align-items: center;
  }
  .tool-button {
    // margin-top: 20px;
    margin-left: 10px;
  }
  .open-schedule {
    text-align: left;
    margin: 20px 0 10px 0;
    font-size: 26px;
    span {
      display: block;
    }
    .set-open {
      color: #409eff;
      margin-bottom: 5px;
    }
    .el-button {
      color: #e44646;
      font-size: 28px;
      font-style: normal;
    }
    .budget-span {
      color: #409eff;
    }
  }
}

.editNameInput {
  background: #efefef;
  border-radius: 5px;
}
.mr5 {
  margin-right: 5px;
}
</style>
